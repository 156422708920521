import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-documentos',
  templateUrl: './documentos.component.html',
  styles: []
})
export class DocumentosComponent implements OnInit {
    pageSize = 0;
    pageSizeOptions = 0;
    length = 0;

    documentos = [{
            nombre: "Manual de responsabilidades administrativas y penales",
            size: "10MB",
            img: "./assets/docico/pdf.png",
            url: "https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/documentos%2FMANUAL%20RESPONSABILIDADES%20ADMINSITRATIVAS%20Y%20PENALES%20SNA%20ETHOS.pdf?alt=media&token=51a9832e-0d8c-4330-b9d3-a7f7a1e44595",
            autor: " SNA Ethos"
        }, {
            nombre: "Manual de derecho corporativo",
            size: "436kb",
            img: "./assets/docico/pdf.png",
            url: "https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/documentos%2FManualDerechoCorporativoCorr.pdf?alt=media&token=5a9e15be-f8c5-4c2c-88df-c042c394f49d",
            autor: "Patricia Villa Berger y Luis Gerardo Ramírez Villela"
        }, {
            nombre: "Manual prevención lavado de dinero",
            size: "1.8MB",
            img: "./assets/docico/pdf.png",
            url: "https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/documentos%2FManualPrevenci%C3%B3nLavadoDineroCorr.pdf?alt=media&token=afd17b9a-786e-49e6-8056-0db6b2865db2",
            autor: "Patricia Villa Berger"
        }, {
            nombre: "Los negocios contra la corrupción",
            size: "1.33MB",
            img: "./assets/docico/pdf.png",
            url: "https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/documentos%2F0.%20LosZnegociosZcontraZlaZcorrupcion.pdf?alt=media&token=b52a8de6-ef8d-4958-9b20-d7892918ca0a",
            // autor: "Transparency International"
        }, {
            nombre: "Glosario de Término de Integridad Corporativa",
            size: "351KB",
            img: "./assets/docico/pdf.png",
            url: "https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/documentos%2F1.%20Glosario%20de%20T%C3%A9rmino%20de%20Integridad%20Corporativa.pdf?alt=media&token=227e300f-932c-4697-937d-655a01e3dde3",
            // autor: "Transparency International"
        }, {
            nombre: "Modelo de Código de Conducta",
            size: "270KB",
            img: "./assets/docico/pdf.png",
            url: "https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/documentos%2F2.%20Modelo%20de%20C%C3%B3digo%20de%20Conducta.pdf?alt=media&token=f76ef27b-5e5e-48ac-84de-06a234c67025",
            // autor: "Transparency International"
        }, {
            nombre: "Manual de Implementación",
            size: "324KB",
            img: "./assets/docico/pdf.png",
            url: "https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/documentos%2F3.%20Manual%20de%20Implementaci%C3%B3n.pdf?alt=media&token=42e6182a-f505-49e9-8679-84bb908e7eed",
            // autor: "Transparency International"
        }, {
            nombre: "Protocolo Análisis de Riesgos",
            size: "408KB",
            img: "./assets/docico/pdf.png",
            url: "https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/documentos%2F4.%20Protocolo%20An%C3%A1lisis%20de%20Riesgos.pdf?alt=media&token=7d0f95f2-9a35-45bc-8843-6cbc7f63f719",
            // autor: "Transparency International"
        }, {
            nombre: "Herramienta Autodiagnóstico",
            size: "777KB",
            img: "./assets/docico/pdf.png",
            url: "https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/documentos%2F5.%20Herramienta%20Autodiagn%C3%B3stico.pdf?alt=media&token=6ddfc21a-764c-4a22-b6b7-2dae254fe74e",
            // autor: "Transparency International"
        }, {
            nombre: "Mapeo y Reporte Buenas Prácticas",
            size: "496KB",
            img: "./assets/docico/pdf.png",
            url: "https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/documentos%2F6.%20Mapeo%20y%20Reporte%20Buenas%20Pr%C3%A1cticas.pdf?alt=media&token=4e2d0455-c6fb-4ad4-82fd-24e55d662e92",
            // autor: "Transparency International"
        }, {
            nombre: "Participacion ciudadana y combate a la corrupcion ebook",
            size: "1.42MBB",
            img: "./assets/docico/pdf.png",
            url: "https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/documentos%2Fparticipacion_ciudadana_y_combate_a_la_corrupcion_ebook.pdf?alt=media&token=faf25104-0f12-4631-97ad-5e2981972044",
            // autor: "Transparency International"
        }
    ];

    constructor() { }

    ngOnInit() {
        this.length = this.documentos.length;
    }

    pageEvent() {

    }
}
