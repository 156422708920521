import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PrincipalComponent } from './components/principal/principal.component';
import { ProyectosComponent } from './components/proyectos/proyectos.component';
import { NoticiasComponent } from './components/noticias/noticias.component';
import { MejoresPracticasComponent } from './components/mejores-practicas/mejores-practicas.component';
import { InfografiasComponent } from './components/infografias/infografias.component';
import { DocumentosComponent } from './components/documentos/documentos.component';
import { EntradaBlogComponent } from './components/entrada-blog/entrada-blog.component';
import { FormacionComponent } from './components/formacion/formacion.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { DefaultComponent } from './components/default/default.component';

const routes: Routes = [
    { path: '',             component: PrincipalComponent },
    { path: 'proyectos',    component: ProyectosComponent },
    { path: 'noticias',     component: NoticiasComponent },
    { path: 'practicas',    component: MejoresPracticasComponent },
    { path: 'infografias',  component: InfografiasComponent },
    { path: 'documentos',   component: DocumentosComponent },
    { path: 'noticias',     component: NoticiasComponent },
    { path: 'infografias',  component: InfografiasComponent },
    { path: 'documentos',   component: DocumentosComponent },
    { path: 'entrada',      component: EntradaBlogComponent },
    { path: 'formacion',    component: FormacionComponent },
    { path: 'contacto',     component: ContactoComponent },
    { path: 'observatorio', component: MejoresPracticasComponent },
    { path: '404',          component: DefaultComponent },

    { path: '**', pathMatch: 'full', redirectTo: '404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
// , { useHash: true, initialNavigation: 'enabled' }
