import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-responsabilidad',
  templateUrl: './responsabilidad.component.html',
  styles: []
})
export class ResponsabilidadComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
