import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';

const httpOptions = {
    headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type':  'application/json',
        'Access-Control-Allow-Origin': '*',
        "Access-Control-Allow-Methods": 'GET,POST,PATCH,DELETE,PUT,OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, content-type'
    })
};

@Injectable({
    providedIn: 'root'
})
export class DataService {

    urlFirebase = 'https://durango-integra.firebaseio.com';
    urlEstados = `${ this.urlFirebase }/estados.json`;
    urlMunicipios = `${ this.urlFirebase }/municipios.json`;

    constructor(private http: HttpClient) { }

    sendCMS ( data: any ) {
      this.http.post(`${this.urlFirebase}/cms.json`, data, httpOptions)
        .subscribe(report => {
          Swal.fire('Gracias por contactarnos.')
      })
    }

    getInfografias() {
      return this.http.get(`${this.urlFirebase}/infografias.json`).
        pipe(map( this.crearArreglo ));
    }

    crearArreglo(obj: object) {
      const temp: any[] = [];
      if(obj === null) { return []; }
      Object.keys(obj).forEach( key => {
        const objeto = obj[key];
        objeto.id = key;
        temp.push();
      });
      return temp;
    }
}
