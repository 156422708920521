import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'principal';
    onActivate(event) {
        window.scrollTo(0, 20); // how far to scroll on each step
        // let scrollToTop = window.setInterval(() => {
        //     let pos = window.pageYOffset;
        //     if (pos > 0) {
        //     } else {
        //         window.clearInterval(scrollToTop);
        //     }
        // }, 16);
    }
}
