import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-infografias',
    templateUrl: './infografias.component.html',
    styles: []
})
export class InfografiasComponent implements OnInit {

    infografias = [
    //     {
    //     titulo: "Infografia",
    //     descripcion: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quae tempora ratione nulla aperiam voluptates voluptate error nemo consequatur ut optio."
    // },{
    //     titulo: "Infografia",
    //     descripcion: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. "
    // },{
    //     titulo: "Infografia",
    //     descripcion: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. "
    // },{
    //     titulo: "Infografia",
    //     descripcion: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quae tempora ratione nulla aperiam voluptates voluptate error nemo consequatur ut optio."
    // },{
    //     titulo: "Infografia",
    //     descripcion: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quae tempora ratione nulla aperiam voluptates voluptate error nemo consequatur ut optio."
    // },
]

    constructor() { }

    ngOnInit() {
    }

}
