import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-formacion',
  templateUrl: './formacion.component.html',
  styles: []
})
export class FormacionComponent implements OnInit {

    enlaces = [{
        titulo: "Sistema Nacional Anticorrupción",
        descripcion: "El nuevo régimen de responsabilidades administrativas y penales vinculadas a hechos de corrupción",
        url: "http://mooc.rendiciondecuentas.org.mx/",
        // fecha:"22/05/2019",
        img: "http://mooc.rendiciondecuentas.org.mx/pluginfile.php/1/theme_klass/slide1image/1560272722/cabeza-home-01.jpg",
        local: false
    },{
        titulo: "Tecnologías Sociales para Combatir la Corrupción",
        descripcion: "¿Quieres saber cómo la #tecnología puede ser una aliada en la lucha contra la corrupción? ¡Te invitamos a este #webinar para averiguarlo!.",
        url: "https://mailchi.mp/0f4f790018e3/inscripcion-webinar",
        fecha:"22/05/2019",
        img: "./assets/eventos/webinar_nexuro.jpg",
        local: false
    },]

    constructor() { }

    ngOnInit() {
    }

}
