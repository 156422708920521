import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material';

@Component({
  selector: 'app-noticias',
  templateUrl: './noticias.component.html',
  styles: []
})
export class NoticiasComponent implements OnInit {
    length = 100;
    pageSize = 10;
    pageSizeOptions: number[] = [5, 10, 25, 100];

    pageEvent: PageEvent;

    eventos = [{
        titulo: "Hackaton Análisis de la Política Estatal Anticorrupción",
        descripcion: "Se convocaron a jóvenes talentosos de Durango para que desarrollaran, mediante la modalidad de un Hackathon, soluciones innovadoras al problema de la corrupción en el Estado de Durango.",
        // url: "https://integra-app.herokuapp.com/processes/analisis-anticorrupcion",
        fecha:"12/05/2019",
        img: "https://scontent.fdgo1-1.fna.fbcdn.net/v/t1.0-9/60457207_305586210388538_7340510034155012096_o.jpg?_nc_cat=101&_nc_eui2=AeEGXBQB7tIx2tPNNTfor4gokSfi6-3sDeb3j4HanCiNVWqi7qfvlvwG3AGH88guHs3KosZz94pPvi6tUvkfrL2Ax5hDAW37a2hfLLFN6fSUgQ&_nc_ht=scontent.fdgo1-1.fna&oh=2553a23c4d170270f8ec37972c727f52&oe=5D7BB61B",
        local: false
    },{
        titulo: "SOCIALIZACIÓN DE LA PROPUESTA DE POLÍTICA ESTATAL ANTICORRUPCIÓN",
        descripcion: "El Sistema Local Anticorrupción del Estado de Durango, llevó a cabo cuatro mesas de análiss para socializar la propuesta de la POLÍTICA ESTATAL ANTICORRUPCIÓN PARA EL ESTADO DE DURANGO.",
        // url: "https://integra-app.herokuapp.com/processes/propuesta-politica-anticorrupcion",
        fecha:"10/06/2019",
        // img: "https://integra-app.s3.us-east-2.amazonaws.com/uploads/decidim/participatory_process/hero_image/4/video_2_SOLIDARIA_GOMEZ_DGO_corr_2_Moment_2_.jpg?X-Amz-Expires=600&X-Amz-Date=20190701T204430Z&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAWLUPTF2ZW3ZDBQIX%2F20190701%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-SignedHeaders=host&X-Amz-Signature=db7902cd07d99bf4b1524a7e6f033a61f91668fca266bcc419ebaec9c1451c8d",
        local: false
    }]

    constructor() {
    }

    ngOnInit() {
    }

    setPageSizeOptions(setPageSizeOptionsInput: string) {
        this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }

}
