import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from '../../services/data.service';

export interface contactEmail {
    email: string;
    name: string;
    message: string;
    revisado: string;
}

@Component({
    selector: 'app-contacto',
    templateUrl: './contacto.component.html',
    styles: []
})

export class ContactoComponent implements OnInit {

    formularioEnviado = (sessionStorage.getItem("envio_formulario") === 'false');

    constructor (private dataService: DataService) { }

    ngOnInit () { }

    sendEmail ( form: NgForm ) {
        this.formularioEnviado = true;
        sessionStorage.setItem("envio_formulario", "false");
        this.dataService.sendCMS({revisado: false, ... form.value});
        form.reset();
    }



}
