import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { Location } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PrincipalComponent } from './components/principal/principal.component';
import { DragScrollModule } from 'ngx-drag-scroll';
import { MatVideoModule } from 'mat-video';
import { MatProgressBarModule, MatRadioModule, MatSliderModule } from '@angular/material'

import { DeviceDetectorModule } from 'ngx-device-detector';
import { FacebookModule } from 'ngx-facebook';
// import { NgxTweetModule } from 'ngx-tweet';
// import { NgxTwitterTimelineModule } from 'ngx-twitter-timeline';
/* ANGULAR MATERIAL */
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule, MatCheckboxModule } from '@angular/material';
import { MatPaginatorModule } from '@angular/material/paginator';

/* COMPONENTS */
import { NavbarComponent } from './components/navbar/navbar.component';
import { ResponsabilidadComponent } from './fragment/responsabilidad/responsabilidad.component';
import { FooterComponent } from './fragment/footer/footer.component';
import { ProyectosComponent } from './components/proyectos/proyectos.component';
import { NoticiasComponent } from './components/noticias/noticias.component';
import { MejoresPracticasComponent } from './components/mejores-practicas/mejores-practicas.component';
import { InfografiasComponent } from './components/infografias/infografias.component';
import { DocumentosComponent } from './components/documentos/documentos.component';
import { EntradaBlogComponent } from './components/entrada-blog/entrada-blog.component';
import { VideoComponent } from './fragment/video/video.component';
import { FormacionComponent } from './components/formacion/formacion.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { DefaultComponent } from './components/default/default.component';

@NgModule({
    declarations: [
        AppComponent,
        PrincipalComponent,
        NavbarComponent,
        ResponsabilidadComponent,
        FooterComponent,
        ProyectosComponent,
        NoticiasComponent,
        MejoresPracticasComponent,
        InfografiasComponent,
        DocumentosComponent,
        EntradaBlogComponent,
        VideoComponent,
        FormacionComponent,
        ContactoComponent,
        DefaultComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatPaginatorModule,
        DragScrollModule,
        MatVideoModule,
        MatProgressBarModule, MatRadioModule, MatSliderModule,
        HttpClientModule,
        DeviceDetectorModule.forRoot(),
        FacebookModule,
        // NgxTweetModule,
        // NgxTwitterTimelineModule,
        FormsModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }

// const __stripTrailingSlash = (Location as any).stripTrailingSlash;
// Location.stripTrailingSlash = function (url) {
//   if (url.endsWith('/')) {
//     url=url;
//   }
//   else {
//     url=url+'/';
//   }
//   const queryString$ = url.match(/([^?]*)?(.*)/);
//   if (queryString$[2].length > 0) {
//     return /[^\/]\/$/.test(queryString$[1]) ? queryString$[1] + '.' + queryString$[2] : __stripTrailingSlash(url);
//   }
//   return /[^\/]\/$/.test(url) ? url + '.' : __stripTrailingSlash(url);
// };
