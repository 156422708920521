import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mejores-practicas',
  templateUrl: './mejores-practicas.component.html',
  styleUrls: []
})
export class MejoresPracticasComponent implements OnInit {
    documentos = [{
        nombre: "METRICA DE GOBIERNO RESULTADOS 2019",
        size: "5MB",
        img: "./assets/docico/pdf.png",
        url: "https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/transparencia%2FMETRICA%20DE%20GOBIERNO%20RESULTADOS%202019.pdf?alt=media&token=04654b8a-36dc-4921-a983-1c38550bc348",
        autor: ""
    },{
        nombre: "Metrica Reporte 2019",
        size: "6MB",
        img: "./assets/docico/pdf.png",
        url: "https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/transparencia%2FMetrica-Reporte-2019.pdf?alt=media&token=f6384c50-0f99-40f8-83fa-415d87bdb528",
        autor: ""
    },{
        nombre: "CURSO DERECHO A LA INFORMACION",
        size: "2MB",
        img: "./assets/docico/pdf.png",
        url: "https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/transparencia%2FCURSO%20DERECHO%20A%20LA%20INFORMACION.pdf?alt=media&token=298b68b0-83a1-4507-9d26-e61024cf0a82",
        autor: ""
    },{
        nombre: "TRASPARENCIA PROACTIVA MANUAL DEL PARTICIPANTE",
        size: "7MB",
        img: "./assets/docico/pdf.png",
        url: "https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/transparencia%2FTRASPARENCIA%20PROACTIVA%20MANUAL%20DEL%20PARTICIPANTE.pdf?alt=media&token=e676c212-affd-434b-9b80-ccaa983feb42",
        autor: ""
    }]

    infografias = [{
        img: "./assets/transparencia/infografia_1.jpg",
    },{
        img: "./assets/transparencia/infografia_2.jpg",
    },{
        img: "./assets/transparencia/infografia_3.jpg",
    },]
    constructor() { }

    ngOnInit() {
    }

}
