import { Component, OnInit, HostListener  } from '@angular/core';
// import * as $ from 'jquery';
import Swal from 'sweetalert2';
import { FBVideoComponent } from 'ngx-facebook';

declare var FB;
declare var twttr;

@Component({
    selector: 'app-principal',
    templateUrl: './principal.component.html',
    styles: []
})
export class PrincipalComponent implements OnInit {
    scrHeight:any;
    scrWidth:any;
    visorResponsive:boolean;

    constructor() {
        this.getScreenSize();
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize( event?: any ) {
          this.scrHeight = window.innerHeight;
          this.scrWidth = window.innerWidth;
          ( this.scrWidth < 780 ) ? this.visorResponsive = false : this.visorResponsive = true;
    }

    notas = [
        {
            titulo: 'Hackathon: Análisis de la Política Estatal Anticorrupción',
            video: 'https://www.facebook.com/integradgo/videos/1031185157270999/',
            resumen: 'La Asociación Civil, Solidaria México, en el marco del Programa denominado Integra Durango, que se realiza con el apoyo del Programa de Naciones Unidas para el Desarrollo en México y la Agencia de los Estados Unidos para el Desarrollo Internacional, también conocida por sus siglas en inglés USAID, y la Asociación CANAJOP convocaron a jóvenes talentosos de Durango para que desarrollaran, mediante la modalidad de un Hackaton, soluciones innovadoras al problema de la corrupción en el Estado de Durango.',
            fondo: './assets/fotografias/integra_05.jpg',
            url: 'https://integra-app.herokuapp.com/processes/analisis-anticorrupcion'
        },
        {
            titulo: 'SOCIALIZACIÓN DE LA PROPUESTA DE POLÍTICA ESTATAL ANTICORRUPCIÓN',
            video: 'https://www.facebook.com/integradgo/videos/2416217805289959/',
            resumen: 'El Sistema Local Anticorrupción del Estado de Durango, llevó a cabo cuatro mesas de análiss para socializar la propuesta de la POLÍTICA ESTATAL ANTICORRUPCIÓN PARA EL ESTADO DE DURANGO.',
			fondo: 'https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/img%2Fnoticias%2Fanticorrupcion.png?alt=media&amp;token=6532b63e-d7a0-4d45-b8a7-2c69cbb2d739',
            url: 'https://integra-app.herokuapp.com/processes/propuesta-politica-anticorrupcion'
        }
    ]

    ngOnInit() {
        FB.XFBML.parse();
        // twttr.widgets.load();

        if (typeof(Storage) !== "undefined") {
            if(!sessionStorage.getItem("popup_integra")) {
                Swal.fire({
                    width: 900,
                    imageUrl: './assets/popup.png',
                    inputValidator: function (value) {
                        return new Promise(function (resolve, reject) {
                            if (value === 'Ok') {
                                resolve()
                            } else {
                                reject('You need to select Ukraine :)')
                            }
                        })
                    }
                }).then(function (result) {
                    sessionStorage.setItem("popup_integra", "true");
                });
            }
        }
    }
}
