import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { MatVideoComponent } from 'mat-video';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ["/video.component.css"]
})
export class VideoComponent implements OnInit {
    actual = new Date();
    @ViewChild('video') matVideo: MatVideoComponent;
    video: HTMLVideoElement;

    title = "Ciudamanos"
    playlist = [{
        title: 'Capítulo 0: Prologo Manópolis',
        url_video: 'https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/Serie%2FEpisodio_1.mp4?alt=media&token=65ca2dc9-e442-4691-a977-bdac3c1dff97',
        url_image: 'https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/Serie%2Fportadas%2Fcapitulo_1.jpg?alt=media&token=6d40bf76-a453-4683-946a-effada64d294',
        estreno: '2018-07-18'
    }, {
        title: 'Capítulo 1: Mano mordida',
        url_video: 'https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/Serie%2FEpisodio_2.mp4?alt=media&token=0f963b71-48c3-4b3d-b9a9-46240125b504',
        url_image: 'https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/Serie%2Fportadas%2Fcapitulo_2.jpg?alt=media&token=6ed2fd88-8fd6-4cdd-aa68-b36adb1b140e',
        estreno: '2018-07-18'
    }, {
        title: 'Capítulo 2: Dame la mano',
        url_video: 'https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/Serie%2FEpisodio_3.mp4?alt=media&token=82c08c3b-0d78-491b-b04c-52f9d3c2f82a',
        url_image: 'https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/Serie%2Fportadas%2Fcapitulo_3.jpg?alt=media&token=980d1d75-c9dc-40d4-b017-cd501459780b',
        estreno: '2018-07-18'
    }, {
        title: 'Capítulo 3: Manos Enlazadas',
        url_video: 'https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/Serie%2FEpisodio_4.mp4?alt=media&token=62110afa-dc57-452b-bec1-6be3dfe339a6',
        url_image: 'https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/Serie%2Fportadas%2Fmano-enlazada.jpg?alt=media&token=ccfe9960-3245-4adf-9bdd-64637697494c',
        estreno: '2018-07-18'
    }, {
        title: 'Capítulo 4: Mano íntegra',
        url_video: 'https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/Serie%2FEpisodio_5.mp4?alt=media&token=52f14998-5af0-49fe-b110-7ff5f7ceaa7f',
        url_image: 'https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/Serie%2Fportadas%2Fmano-integra.jpg?alt=media&token=23e83288-839b-4827-8c09-9331e833c13c',
        estreno: '2018-07-18'
    },{
        title: 'Capítulo 5: Mano Aceitada',
        url_video: 'https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/Serie%2FEpisodio_6.mp4?alt=media&token=b1a456bc-b338-4672-95db-17ebad84859a',
        url_image: 'https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/Serie%2Fportadas%2Fmano-aceitada-cap.jpg?alt=media&token=18d59c7f-23fc-4de0-b185-9cee281f8146',
        estreno: '2019-07-19'
    },
    {
        title: 'Capítulo 6: Manos en la Mesa',
        url_video: 'https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/Serie%2FEpisodio_7.mp4?alt=media&token=ff10bb84-c7d8-4673-9527-8b4c88239757',
        url_image: 'https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/Serie%2Fportadas%2Fmenos-en-la-mesa.jpg?alt=media&token=57ee5c11-77b8-4479-982a-30d2fe11432e',
        estreno: '2019-07-22'
    }, {
        title: 'Capítulo 7: Mano Ambiciosa',
        url_video: 'https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/Serie%2FEpisodio_8.mp4?alt=media&token=3f454127-e5bf-45cb-8b8c-5d2b4d657e61',
        url_image: 'https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/Serie%2Fportadas%2Fmano-ambiciosa.jpg?alt=media&token=a3ae0ada-beff-45ae-9d48-75abb57ece02',
        estreno: '2019-07-24'
    }, {
        title: 'Capítulo 8: Mano Atada',
        url_video: 'https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/Serie%2FEpisodio_9.mp4?alt=media&token=9dd0f6aa-5cf7-446c-adef-bdb0aae3d5ae',
        url_image: 'https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/Serie%2Fportadas%2Fmanos-enlazadas.jpg?alt=media&token=8fc84384-fee1-4517-bf9a-c92c4e67ac32',
        estreno: '2019-07-26'
    }, {
        title: 'Capítulo 9: Ciudamanos Unidos',
        url_video: 'https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/Serie%2FEpisodio_10.mp4?alt=media&token=6be7d160-47d0-4901-985e-8cbc053bc2e6',
        url_image: 'https://firebasestorage.googleapis.com/v0/b/durango-integra.appspot.com/o/Serie%2Fportadas%2Fciudamanos-unidos.jpg?alt=media&token=ace6fc63-8f88-42bd-ba5f-c3beee216869',
        estreno: '2019-07-29'
    }
]

    idActual = 0;
    tipoReproductor:boolean = true;
    videoActual = this.playlist[this.idActual]

    constructor( private renderer: Renderer2, private deviceService: DeviceDetectorService ) {
        this.videoActual = this.playlist[0];
    }

    ngOnInit() {
        if(this.deviceService.browser !== "Safari") {
            this.tipoReproductor = false;
        } else {
            this.tipoReproductor = true;
            setTimeout( () => {
                this.video = this.matVideo.getVideoTag();
                this.video.addEventListener('ended', () => {
                    ( this.playlist.length > (this.idActual + 1) ) ? this.idActual += 1 : this.idActual = 0;
                    this.videoActual = this.playlist[this.idActual];
                });
            }, 1000);
        }
    }

    videoClip(s){
        this.idActual = s;
        this.videoActual = this.playlist[this.idActual];
    }

    isEstreno(index: number) {
        return (new Date(this.playlist[index].estreno) < this.actual)
    }
}
